
import { mapState } from "vuex";
import { defineComponent, ref, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import APISubcriptionPlans from "@/components/modals/general/APISubsciptionPlans.vue";
import Globals from "@/views/common/Globals.vue";
import { ElNotification } from "element-plus";
import { ElLoading } from "element-plus";
import alasql from "alasql";

interface IApi {
  id: number;
  name: string;
  createdDate: string;
  status: string;
}
export default defineComponent({
  name: "ApiList",
  mixins: [Globals],
  components: {
    // APISubcriptionPlans,
  },

  data() {
    const currentDate = new Date();
    let current_date;
    let day_interval = parseInt(process.env.VUE_APP_PREVIOUS_DAY||"1");
    if (currentDate.getDay() === 1) { // 1 represents Monday
      current_date = new Date(currentDate.getTime() - day_interval * 24 * 60 * 60 * 1000); // subtract 3 days
    } else {
      current_date = new Date(currentDate.getTime() - 24 * 60 * 60 * 1000); // subtract 1 day
    }
    return {
      layout: "grid",
      check: "",
      urlData: "",
      render: false,
      category: "",
      group: "",
      apiData: [] as any,
      extra: [] as any,
      // apisubscriptiondata: [],
      urlArray: [],
      indexes: [],
      apiId: "",
      flag: false,
      searchKey: "",
      searchFlag: true,
      initAPI: [],
      showcase: "",
      showcaseValue: "",
      groupData: [] as any,
      categoryData: ["Wealth Management", "Insurance"],
      apiGroups: [],
      apisGroup: [],
      fromDate: current_date,
      toDate: this.dateConverter(new Date(current_date.setHours(23, 59, 59, 99))),
      apiGroupDict : {}
    };
  },

  methods: {
    //OpenDoc Method
    downloadJson(postmanJson, groupName){
      // debugger;
      if(!postmanJson || postmanJson === "undefined"){
        ElNotification({
            title: 'Error',
            message: "We apologize, but this document is temporarily inaccessible. Please reach out to administrator for further assistance.",
            type: 'error',
            duration: 2000,
            position: 'top-right'
        });
      }else{
        let dataStr = JSON.parse(postmanJson);
        dataStr = JSON.stringify(dataStr, null, 2);  // Convert JSON object to string
        const dataBlob = new Blob([dataStr], { type: 'application/json' });  // Create a Blob from the string
        const url = URL.createObjectURL(dataBlob);  // Create a URL for the Blob

        // Create a temporary link element
        const link = document.createElement('a');
        link.href = url;
        groupName = groupName.replace(" ", "_");
        link.download = 'postman_collection_'+ groupName + ".json" ;  // Set the download attribute with a filename
        document.body.appendChild(link);  // Append the link to the document body
        link.click();  // Programmatically click the link to trigger the download
        document.body.removeChild(link);  // Remove the link from the document body
        URL.revokeObjectURL(url);  // Revoke the URL to free up resources
      }
      
    },
    openLinkInNewTab(url) {
      window.open(url, "_blank");
    },

    //dateConverter
    dateConverter(val) {
      const year = new Date(val).getFullYear();
      const month = String(new Date(val).getMonth() + 1).padStart(2, "0");
      const day = String(new Date(val).getDate()).padStart(2, "0");
      const hour = val.getHours();
      const minute = val.getMinutes();
      const second = val.getSeconds();

      return `${year}-${month}-${day}T${hour}:${minute}:${second}`
    },
    //Searching Method
    searchICards() {
      this.apiGroups.splice(0, this.apiGroups.length, ...this.initAPI);

      if (this.searchKey !== "") {
        let results = [];
        for (let j = 0; j < this.apiGroups.length; j++) {
          if (
            this.searchingFunc(this.apiGroups[j].description, this.searchKey)
          ) {
            results.push(this.apiGroups[j]);
          }
        }
        this.apiGroups.splice(0, this.apiGroups.length, ...results);
        if (this.apiGroups.length == 0 && this.searchFlag == true) {
          this.searchFlag = false;
          ElNotification({
            title: "Warning",
            message: "No Records Found!",
            type: "warning",
            duration: 2000,
            position: "top-right",
          });
        } else if (this.apiGroups.length != 0) {
          this.searchFlag = true;
        }
      }
    },
    //Search Query Method
    searchingFunc(obj, value) {
      // let key in obj {
      if (
        !Number.isInteger(obj) &&
        !(typeof obj === "object") &&
        !(typeof obj === "number")
      ) {
        if (obj.toLowerCase().indexOf(value.toLowerCase()) != -1) {
          return true;
        }
      }
      // }
      return false;
    },

    //get organizations API
    getOneSbApi() {
      // const today = new Date();
      // const year = today.getFullYear();
      // const month = String(today.getMonth() + 1).padStart(2, "0");
      // const day = String(today.getDate()).padStart(2, "0");
      // const formatData = `${year}-${month}-${day}`;
      this.fromDate.setHours(0, 0, 0, 0);
      const loading = ElLoading.service({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      ApiService.get(
        `/organizations/1SB/apis?fromDate=${this.dateConverter(this.fromDate)}&toDate=${this.toDate}&frequency=daily`
      )
        .then((data) => {
          const result = alasql(
            "SELECT apiGroup,MAX(id) AS ID,GROUP_CONCAT(DISTINCT gatewayName) AS gatewayName,GROUP_CONCAT(DISTINCT apiGroupDocsUrl) AS docsUrl,GROUP_CONCAT(DISTINCT apiGroup) AS apiGroupName,GROUP_CONCAT(DISTINCT apiGroupDescription) AS description,GROUP_CONCAT(DISTINCT latestStatus) AS Status,AVG(averageLatency) AS averageLatency,AVG(availabilityPercentage) AS availabilityPercentage,GROUP_CONCAT(DISTINCT postmanCollection) AS postmanCollection FROM  ? GROUP BY apiGroup",
            [data.data.data.api_list]
          );
          this.apiGroupDict = data.data.data.api_group_list.reduce((acc, item) => {
            acc[item.id] = item;
            return acc;
          }, {});
          console.log("result",result);
          console.log("this.apiGroupDict-->>", this.apiGroupDict);
          this.apiGroupDict
          const Test1 = result.map((item) => {
            item.Status = item.Status.replaceAll("null,", "")
            item.Status = item.Status.replaceAll(",null", "")
            item.docsUrl = item.docsUrl.replaceAll("null,", "")
            item.docsUrl = item.docsUrl.replaceAll(",null", "")
            // item.postmanCollection = item.postmanCollection.replaceAll(",null", "")
            // item.postmanCollection = item.postmanCollection.replaceAll("null,", "")
            if (item.Status.split(',').length > 1) {
              return {
                apiGroup: item.apiGroup,
                ID: item.ID,
                gatewayName: item.gatewayName,
                docsUrl: item.docsUrl,
                postmanCollection: this.apiGroupDict[item.apiGroup] ? this.apiGroupDict[item.apiGroup].postmanCollection ? JSON.stringify(this.apiGroupDict[item.apiGroup].postmanCollection) : "" : "",
                apiGroupName: item.apiGroupName,
                description: item.description,
                Status: "partial",
                averageLatency: item.averageLatency,
                availabilityPercentage: item.availabilityPercentage
              }
            } else if (item.Status.split(',')[0] === 'null') {
              return {
                apiGroup: item.apiGroup,
                ID: item.ID,
                gatewayName: item.gatewayName,
                docsUrl: item.docsUrl,
                postmanCollection: this.apiGroupDict[item.apiGroup] ? this.apiGroupDict[item.apiGroup].postmanCollection ? JSON.stringify(this.apiGroupDict[item.apiGroup].postmanCollection) : "" : "",
                apiGroupName: item.apiGroupName,
                description: item.description,
                Status: "NA",
                 averageLatency: item.averageLatency,
                availabilityPercentage: item.availabilityPercentage
              }


            }
            else {
              return {
                apiGroup: item.apiGroup,
                ID: item.ID,
                gatewayName: item.gatewayName,
                docsUrl: item.docsUrl,
                postmanCollection: this.apiGroupDict[item.apiGroup] ? this.apiGroupDict[item.apiGroup].postmanCollection ? JSON.stringify(this.apiGroupDict[item.apiGroup].postmanCollection) : "" : "",
                apiGroupName: item.apiGroupName,
                description: item.description,
                Status: item.Status,
                averageLatency: item.averageLatency,
                availabilityPercentage: item.availabilityPercentage
              }
            }
          })
          this.apiGroups = [...Test1];
          this.initAPI = [...Test1];
          this.apisGroup = [...Test1];
          this.render = true;
          // this.apiGroups = [...result];
          // this.initAPI = [...result];
          // this.apisGroup = [...result];
          // this.render = true;
          loading.close();
          // result.map((item, index) => {
          //   ApiService.get(`/organizations/1SB/apis/${item.ID}`)
          //     .then((data) => {
          //       const { apiGroup, description, docsUrl, gatewayName } =
          //         data.data.data;

          //       this.apisGroup.push({
          //         description: description,
          //         apiGroupName: apiGroup,
          //         docsUrl: docsUrl,
          //         gatewayName: gatewayName,
          //         Status: item.Status,
          //         availabilityPercentage: item.availabilityPercentage,
          //         averageLatency: item.averageLatency,
          //       });
          //       this.apiGroups.push({
          //         description: description,
          //         apiGroupName: apiGroup,
          //         docsUrl: docsUrl,
          //         gatewayName: gatewayName,
          //         Status: item.Status,
          //         availabilityPercentage: item.availabilityPercentage,
          //         averageLatency: item.averageLatency,
          //       });
          //       this.initAPI.push({
          //         description: description,
          //         apiGroupName: apiGroup,
          //         docsUrl: docsUrl,
          //         gatewayName: gatewayName,
          //         Status: item.Status,
          //         availabilityPercentage: item.availabilityPercentage,
          //         averageLatency: item.averageLatency,
          //       });
          //       loading.close();
          //       this.render = true;
          //     })
          //     .catch((error) => {
          //       console.log("Error Inside Details", error);
          //     });
          // });
        })
        .catch((error) => {
          loading.close();
          console.log("Error in Get1SBAPI", error);
        });
    },

    // SendingId(id) {
    //   this.apiId = id;
    //   this.flag = true;
    // },

    // displayData(data) {
    //   this.apiData = [];
    //   this.apiData = JSON.parse(JSON.stringify(data));
    //   this.extra = JSON.parse(JSON.stringify(data));
    //   if (this.apiData.length != 0) {
    //     this.render = true;
    //   }
    //   this.initAPI.splice(0, this.apiData.length, ...this.apiData);
    // },

    //Filter Category Method
    FilterCategory() {
      if (this.category === "") {
        this.apiGroups = this.apisGroup;
      }
      if (this.category === "Wealth Management") {
        this.apiGroups = this.apisGroup.filter(
          (item) => item.gatewayName === "Wealth Managment"
        );
      }

      if (this.category === "Insurance") {
        this.apiGroups = this.apisGroup.filter(
          (item) => item.gatewayName === "Insurance"
        );
      }
    },

    // apiList() {
    //   var link = "";

    //   if (this.category == "") {
    //     link = "api";
    //   } else {
    //     link = "api?category="+this.category;
    //   }

    //   const loading = ElLoading.service({
    //     lock: true,
    //     text: "Loading",
    //     background: "rgba(0, 0, 0, 0.7)",
    //   });

    //   ApiService.get(link)
    //     .then(({ data }) => {
    //       loading.close();
    //       this.displayData(data.data);
    //     })
    //     .catch((error) => {
    //       loading.close();
    //       this.displayData([]); //this line added to counter bug of no API display
    //       ElNotification({
    //         title: "Warning",
    //         //message: "No APIs exists with '" + this.category + "' category",
    //         message: JSON.stringify(
    //           error.response.data["errors"][0]["errorMessage"]
    //         ),
    //         type: "warning",
    //         duration: 2000,
    //         position: "top-right",
    //       });
    //     });
    // },

    // groupList() {
    //   console.log("this is showcased value", this.group);
    //   var link = "";
    //   if (this.group == "") {
    //     link = "/api/group";
    //   } else {
    //     link = "/api/group";
    //   }

    //   const loading = ElLoading.service({
    //     lock: true,
    //     text: "Loading",
    //     background: "rgba(0, 0, 0, 0.7)",
    //   });

    //   ApiService.get(link )
    //     .then(({ data }) => {
    //       loading.close();
    //       this.groupData = JSON.parse(JSON.stringify(data));
    //       for (let i = 0; i<this.groupData.data.length; i++){
    //         if (this.groupData.data && this.groupData.data[i] && 'category' in this.groupData.data[i] && !(this.categoryData.includes(this.groupData.data[i]['category']))){
    //           this.categoryData.push(this.groupData.data[i]['category'])
    //           console.log("Category Data",)
    //         }
    //       }
    //       console.log("GROUP Data",this.groupData);
    //       console.log("Category Data",this.categoryData)
    //     })
    //     .catch((error) => {
    //       debugger;
    //       loading.close();
    //       this.displayData([]); //this line added to counter bug of no API display
    //       ElNotification({
    //         title: "Warning",
    //         //message: "No APIs exists with '" + this.category + "' category",
    //         message: JSON.stringify(
    //           error.response.data["errors"][0]["errorMessage"]
    //         ),
    //         type: "warning",
    //         duration: 2000,
    //         position: "top-right",
    //       });
    //     });
    // },
  },

  mounted() {
    // this.FilterCategory();
    // this.groupList();
    setCurrentPageBreadcrumbs("Library", ["API"]);
    this.getOneSbApi();
  },

  computed: {
    ...mapState(["orgRole", "userRole"]),
  },
});
